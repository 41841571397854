import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import { Auth } from "aws-amplify";
import "highlight.js";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";

function PreviewDialog({ fileNameKey, setOpen }) {
  const [modelUrl, setModelUrl] = React.useState("");
  const [thumbnailUrl, setThumbnailUrl] = React.useState("");
  const [modelCode, setModelCode] = React.useState("");

  React.useEffect(() => {
    async function generateConfig() {
      const fileName = `${fileNameKey.replace("pub/", "")}`;

      const fileNameNoEx = fileName.split(".")[0];

      const credentials = await Auth.currentUserCredentials();
      console.log("identityId", credentials.identityId);

      const modelUrlRaw = `https://cdn.ar.klanten.gladior.com/protected/${credentials.identityId}/pub/${fileName}`;
      const thumbnailUrlRaw = `https://cdn.ar.klanten.gladior.com/protected/${credentials.identityId}/thumbnail/${fileNameNoEx}.webp`;

      setModelUrl(modelUrlRaw);
      setThumbnailUrl(thumbnailUrlRaw);

      setModelCode(
        `<model-viewer alt="${fileNameNoEx}" src="${modelUrlRaw}" ar poster="${thumbnailUrlRaw}" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>`
      );
    }

    generateConfig();
  }, [fileNameKey]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Preview 3D model</DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ mt: 4, width: "500px" }}>
          <CardContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <style>
                {
                  /* CSS */ `
              model-viewer {
                width: 300px;
                height: 300px;
              };
              `
                }
              </style>
              <model-viewer
                id="mv"
                src={modelUrl}
                shadow-intensity="1"
                camera-controls
                auto-rotate
                ar
              ></model-viewer>
              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel>3D model URL</InputLabel>
                <FilledInput
                  type={"text"}
                  value={modelUrl}
                  label="3D model URL"
                />
              </FormControl>
              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel>Thumbnail/poster URL</InputLabel>
                <FilledInput
                  type={"text"}
                  value={thumbnailUrl}
                  label="Thumbnail/poster URL"
                />
              </FormControl>
              <TextField
                variant="filled"
                fullWidth
                rows={8}
                label="HTML Code"
                value={modelCode}
                multiline
              />
            </Stack>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Sluit venster</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;
