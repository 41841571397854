import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import { Auth, Storage } from "aws-amplify";
import "highlight.js";

import { StorageManager } from "@aws-amplify/ui-react-storage";
import { CheckCircle } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  Link,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import slugify from "slugify";

function UploadDialog({ setOpen }) {
  const [previewUrl, setPreviewUrl] = React.useState("");
  const [tempKey, setTempKey] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);

  const [modelUrl, setModelUrl] = React.useState("");
  const [thumbnailUrl, setThumbnailUrl] = React.useState("");
  const [modelCode, setModelCode] = React.useState("");

  const [name3dFile, setName3dFile] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  async function uploadModel() {
    const modelViewer = document.querySelector("#mv");
    // Set to beginning of animation
    modelViewer.pause();
    modelViewer.currentTime = 0;

    // Wait for model-viewer to resize and render.
    await new Promise((resolve) =>
      requestAnimationFrame(() => {
        requestAnimationFrame(() => resolve());
      })
    );

    const posterBlob = await modelViewer.toBlob({
      idealAspect: true,
      mimeType: "image/webp",
      qualityArgument: 0.85,
    });

    // Reset to original state
    modelViewer.play();
    modelViewer.autoRotate = true;
    modelViewer.interactionPrompt = "auto";

    const fileName = `${tempKey.replace("temp/", "")}`;

    let newFileName = slugify(`${name3dFile}.${fileName.split(".")[1]}`);

    const fileNameNoEx = newFileName.split(".")[0];

    await Storage.put(`thumbnail/${fileNameNoEx}.webp`, posterBlob, {
      level: "protected",
      contentType: "image/webp", // contentType is optional
    });

    await Storage.copy(
      {
        key: tempKey,
        level: "protected",
      },
      {
        key: `pub/${newFileName}`,
        level: "protected",
      }
    );

    const credentials = await Auth.currentUserCredentials();
    console.log("identityId", credentials.identityId);

    const modelUrlRaw = `https://cdn.ar.klanten.gladior.com/protected/${credentials.identityId}/pub/${newFileName}`;
    const thumbnailUrlRaw = `https://cdn.ar.klanten.gladior.com/protected/${credentials.identityId}/thumbnail/${fileNameNoEx}.webp`;

    setModelUrl(modelUrlRaw);
    setThumbnailUrl(thumbnailUrlRaw);
    setModelCode(
      `<model-viewer alt="${fileNameNoEx}" src="${modelUrlRaw}" ar poster="${thumbnailUrlRaw}" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>`
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Upload 3D model</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel optional={"glTF of GLB"}>Kies een 3D model</StepLabel>
            <StepContent>
              <StorageManager
                acceptedFileTypes={[".glb", "model/gltf-binary"]}
                accessLevel="protected"
                path="temp/"
                maxFileCount={1}
                isResumable
                onUploadSuccess={async (data) => {
                  setTempKey(data.key);
                  const signedURL = await Storage.get(data.key, {
                    level: "protected",
                  });
                  setName3dFile(data.key.replace("temp/", "").split(".")[0]);
                  setPreviewUrl(signedURL);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
              />
              <Alert sx={{ mt: 4 }} severity="warning">
                <AlertTitle>Let op!</AlertTitle>
                Op dit moment zijn alleen .glb en .gltf bestanden ondersteund.
                <br />
                Heb je een andere bestandsformaat? Gebruik dan{" "}
                <Link href="https://products.aspose.app/3d/conversion">
                  https://products.aspose.app/3d/conversion
                </Link>{" "}
                om je bestand om te zetten.
              </Alert>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Preview & Genereer thumbnail</StepLabel>
            <StepContent>
              <style>
                {
                  /* CSS */ `
              model-viewer {
                width: 400px;
                height: 400px;
              };
              `
                }
              </style>

              <TextField
                fullWidth
                label="Naam"
                variant="filled"
                value={name3dFile}
                onChange={(event) => {
                  setName3dFile(event.target.value);
                }}
              />

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <model-viewer
                  id="mv"
                  src={previewUrl}
                  shadow-intensity="1"
                  camera-controls
                  auto-rotate
                  ar
                ></model-viewer>

                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  onClick={uploadModel}
                >
                  Genereer thumbnail & upload model
                </Button>
                <Alert sx={{ mt: 4 }} severity="info">
                  <AlertTitle>Instucties</AlertTitle>
                  Positioneer het model en genereer een thumbnail. Deze
                  thumbnail wordt getoond wanneer het 3d model nog niet geladen
                  is.
                </Alert>
              </Stack>
            </StepContent>
          </Step>
        </Stepper>
        {activeStep === 2 && (
          <>
            <Card variant="outlined" sx={{ mt: 4, width: "500px" }}>
              <CardContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <CheckCircle color="success" fontSize="large" />
                  <Typography>3D model succesvol geupload!</Typography>
                  <img alt="" style={{ width: "150px" }} src={thumbnailUrl} />
                  <FormControl variant="filled" size="small" fullWidth>
                    <InputLabel>3D model URL</InputLabel>
                    <FilledInput
                      type={"text"}
                      value={modelUrl}
                      label="3D model URL"
                    />
                  </FormControl>
                  <FormControl variant="filled" size="small" fullWidth>
                    <InputLabel>Thumbnail/poster URL</InputLabel>
                    <FilledInput
                      type={"text"}
                      value={thumbnailUrl}
                      label="Thumbnail/poster URL"
                    />
                  </FormControl>
                  <TextField
                    variant="filled"
                    fullWidth
                    rows={8}
                    label="HTML Code"
                    value={modelCode}
                    multiline
                  />
                </Stack>
              </CardContent>
            </Card>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Sluit venster</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadDialog;
