import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import {
  AccountCircle,
  Add,
  Logout,
  Search,
  ThreeDRotationTwoTone,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Amplify, Auth, Storage } from "aws-amplify";
import FuzzySearch from "fuzzy-search";
import React from "react";

import logo from "./logo.svg";

import PreviewDialog from "./PreviewDialog";
import UploadDialog from "./UploadDialog";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

function App() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [searchValue, setSearchValue] = React.useState("");

  const [previewKey, setPreviewKey] = React.useState(null);

  const [identityId, setIdentityId] = React.useState("");

  const openMenu = Boolean(anchorEl);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchFiles() {
    Storage.list("pub/", { level: "protected" })
      .then(({ results }) => {
        setFiles(results);
        console.log(results);
      })
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    fetchFiles();
  }, []);

  React.useEffect(() => {
    async function fetchUser() {
      const credentials = await Auth.currentUserCredentials();
      setIdentityId(credentials.identityId);
    }
    fetchUser();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const vacancySearcher = new FuzzySearch(files, ["key"], {
    caseSensitive: false,
    sort: true,
  });

  const result = vacancySearcher.search(searchValue);

  return (
    <>
      <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
          <>
            {open && (
              <UploadDialog
                setOpen={(op) => {
                  fetchFiles();
                  setOpen(op);
                }}
              />
            )}

            {previewKey && (
              <PreviewDialog
                fileNameKey={previewKey}
                setOpen={(op) => {
                  fetchFiles();

                  if (op === false) {
                    setPreviewKey(null);
                  }
                }}
              />
            )}

            <AppBar elevation={0} color="primary" position="static">
              <Container maxWidth="md">
                <Toolbar disableGutters>
                  <img style={{ height: "35px" }} src={logo} />
                  <Box sx={{ height: "35px", margin: 2 }}>
                    <Divider light orientation="vertical" />
                  </Box>

                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  >
                    AR Portal
                  </Typography>
                  <Tooltip title={user.attributes.email}>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={openMenu ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                    >
                      <AccountCircle htmlColor="#fff" />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        signOut();
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Toolbar>
              </Container>
            </AppBar>
            <Container maxWidth="md" sx={{ mt: 4 }}>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <Search color="disabled" />
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    size="small"
                    placeholder="Filteren.."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Paper>

                <Box>
                  <Button
                    startIcon={<Add />}
                    color="secondary"
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    Upload
                  </Button>
                </Box>
              </Box>

              <Card variant="outlined">
                {files.length === 0 && (
                  <>
                    <Box
                      alignContent="center"
                      display="flex"
                      flexDirection="column"
                      height="100vh"
                      justifyContent="center"
                      width="100%"
                    >
                      <Box>
                        <Box display="flex" justifyContent="center">
                          <ThreeDRotationTwoTone
                            color="disabled"
                            fontSize="large"
                          />
                        </Box>
                        <Typography
                          sx={{ mt: 2 }}
                          align="center"
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          Geen 3D modellen gevonden
                        </Typography>
                        <Typography
                          align="center"
                          color="textSecondary"
                          display="block"
                          gutterBottom
                          variant="caption"
                        >
                          Klik op de upload knop om een 3D model te uploaden
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                {files.length > 0 && (
                  <CardContent>
                    <List>
                      {result.map((file) => {
                        const fileName = `${file.key.replace("pub/", "")}`;

                        const fileNameNoEx = fileName.split(".")[0];

                        const thumbnailUrlRaw = `https://cdn.ar.klanten.gladior.com/protected/${identityId}/thumbnail/${fileNameNoEx}.webp`;

                        return (
                          <ListItemButton
                            onClick={() => {
                              setPreviewKey(file.key);
                            }}
                            key={fileName}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{ width: 50, height: 50 }}
                                variant="square"
                                src={thumbnailUrlRaw}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={fileName}
                              secondary="3D model"
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </CardContent>
                )}
              </Card>
            </Container>
          </>
        )}
      </Authenticator>
    </>
  );
}

export default App;
